<template>
    <content-wrapper
        :breadcrumbs="breadcrumbs"
        comeback-previous
    >
        <v-row>
            <v-col
                cols="12"
                lg="3"
            >
                <teacher-personal-data
                    :read-only="!isEditable"
                    :uuid="tuuid"
                    @deleted="$router.push({ name: 'departments.teachers', params: { uuid }})"
                />
            </v-col>
            <v-col
                cols="12"
                lg="9"
            >
                <v-row>
                    <v-col cols="12">
                        <teacher-departments
                            :read-only="!isEditable"
                            key="teacher-departments"
                            :uuid="tuuid"
                        />
                    </v-col>
                    <v-col cols="12">
                        <teacher-capacity
                            :read-only="!isEditable"
                            key="teacher-capacities"
                            :uuid="tuuid"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </content-wrapper>
</template>

<script>
import ContentWrapper from '@/components/ContentWrapper'
import hasMemberFullNameFilterMixin from '@/components/Member/mixins/hasMemberFullNameFilterMixin'

export default {
    name: 'DepartmentsTeacherProfile',
    props: {
        uuid: String,
        tuuid: String
    },
    components: {
        TeacherCapacity: () => import('@apps/school/components/Teacher/TeacherCapacity'),
        TeacherDepartments: () => import('@apps/school/components/Teacher/TeacherDepartments'),
        TeacherPersonalData: () => import('@apps/school/components/Teacher/TeacherPersonalData'),
        ContentWrapper
    },
    mixins: [
        hasMemberFullNameFilterMixin
    ],
    computed: {
        isEditable() {
            return this.$auth.isAssistant()
        },
        breadcrumbs() {
            return [
                {
                    text: this.$trans('Departments'),
                    to: { name: 'departments.index' }
                },
                {
                    text: this.$trans('Teachers'),
                    to: { name: 'departments.teachers', params: {uuid: this.uuid } }
                }
            ]
        },
    },
    data() {
        return {
            departments: [],
            capacity: [],
            lessonTypes: [],
            isLoadingCapacity: false
        }
    }
}
</script>

<style lang="scss">

</style>
